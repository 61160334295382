import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import getPage from 'helpers/api/getPage'
import separateBlogPostsAndComponents from 'helpers/separateBlogPostsAndComponents'
import { LandingPagePageElementsItem } from 'helpers/api/schema'
import Header from 'components/Header'
import Footer from 'components/Footer'
import MetaFields from 'components/MetaFields'
import Wrapper from 'components/Wrapper'
import PreFooter from 'components/PreFooter'
import { lightColors } from '@totmoney/colors'
import { AdvantagesSection } from 'components/AdvantagesSection'
import { TestimonialsSection } from 'components/TestimonialsSection'
import { homePageData } from 'data/homePageData'
import { FeedbackSection } from 'components/FeedbackSection'
import LottieHero from 'components/LottieHero'
import { MainComponentsSection } from 'components/MainComponentsSection'
import { BenefitSection } from 'components/BenefitSection'
import { RegistrationInfoSection } from 'components/RegistrationInfoSection'
import { DiscountSection } from 'components/DiscountSection'
import { debitCardPageData } from 'data/debitCardPageData'

const Page = ({ meta, components, disclaimer }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const [_, cleanComponents] = separateBlogPostsAndComponents(components) as [any, LandingPagePageElementsItem[]]

  const { preFooter } = debitCardPageData || {}
  const {
    feedbackSection,
    testimonialsSection,
    mainComponentsSection,
    benefitSection,
    registrationInfoSection,
    homeHero,
    discountSection,
    advantagesSection,
    disclaimerMock
  } = homePageData || {}

  return (
    <>
      <MetaFields title={meta.title} description={meta.description} image={meta.image} />
      <Header theme='red' />
      <LottieHero {...homeHero} />
      <BenefitSection {...benefitSection} />
      <MainComponentsSection {...mainComponentsSection} />
      <AdvantagesSection {...advantagesSection} />
      <RegistrationInfoSection {...registrationInfoSection} />
      <DiscountSection {...discountSection} />
      <FeedbackSection {...feedbackSection} />
      <TestimonialsSection {...testimonialsSection} />
      <Wrapper paddingBottom={[0, 0]} bgColor={lightColors.dirt.base}>
        <PreFooter {...preFooter} />
      </Wrapper>
      <Footer disclaimer={disclaimerMock} />
    </>
  )
}

export const getStaticProps = async (context: GetStaticPropsContext<{ page: string }>) => {
  const props = await getPage({ preview: context.preview, slug: 'index' })

  return {
    props
  }
}

export default Page
