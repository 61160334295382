import { BasicBlogPostFragmentFragment } from './api/schema'

const isBlogPost = (
  item: (BasicBlogPostFragmentFragment | Record<string, unknown>) & {
    __typename: string
  }
): item is BasicBlogPostFragmentFragment & { __typename: string } => item.__typename === 'BlogPost'

const separateBlogPostsAndComponents = (
  components: ((Record<string, unknown> | BasicBlogPostFragmentFragment) & {
    __typename: string
  })[]
): [
  (BasicBlogPostFragmentFragment & { __typename: string })[],
  (Record<string, unknown> & { __typename: string })[]
] => [components.filter(isBlogPost), components.filter((el) => !isBlogPost(el))]

export default separateBlogPostsAndComponents
