import BACKGROUNDS from 'data/backgrounds'
import Grid from './Grid'
import Wrapper from './Wrapper'
import { TextBlock } from './TextBlock'
import Image, { ImageProps } from 'next/image'
import { mq } from '@totmoney/grid'

export interface TestimonialsSectionProps {
  title: string
  data: Pick<ImageProps, 'src' | 'alt'>[]
}

export const TestimonialsSection = (props: TestimonialsSectionProps) => {
  return (
    <Wrapper className='testimonials-section' bgColor={BACKGROUNDS['white']}>
      <Grid>
        <TextBlock title={props.title} />
        {!!props.data?.length && (
          <div
            css={{
              marginTop: 24,
              gridColumn: '1/-1',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              columnGap: 24,
              [mq.xs]: { gridTemplateColumns: 'repeat(4, 1fr)' },
              [mq.lg]: { gridColumn: '2/-2' },
              [mq.xl]: { gridColumn: '3/-3' }
            }}
          >
            {props.data.map((testimonial) => (
              <div
                key={testimonial.alt}
                css={{
                  position: 'relative',
                  width: '100%',
                  height: 96,
                  margin: '0px auto'
                }}
              >
                <Image style={{ objectFit: 'contain' }} fill src={testimonial.src} alt={testimonial.alt} />
              </div>
            ))}
          </div>
        )}
      </Grid>
    </Wrapper>
  )
}
