import { CSSObject } from '@emotion/react'
import { mq } from '@totmoney/grid'
import Grid from 'components/Grid'
import BACKGROUNDS from 'data/backgrounds'
import { CarouselWrapper } from './CarouselWrapper'
import { FeedbackCard, FeedbackCardProps } from './FeedbackCard'
import { H2 } from './Typography'
import Wrapper from './Wrapper'

export interface FeedbackSectionProps {
  title: string
  data: FeedbackCardProps[]
}

export const FeedbackSection = ({ title, data }: FeedbackSectionProps) => {
  const leftContainerStyle = {
    gridColumn: '1/-1',

    [mq.xs]: { gridColumn: '3/span 8' },
    [mq.lg]: { gridColumn: '1/span 6' },
    [mq.xl]: { gridColumn: '2/span 5' }
  }
  const rightContainerStyle: CSSObject = {
    gridColumn: '1/-1',
    width: '100%',
    [mq.xs]: { gridColumn: '3/span 8' },
    [mq.lg]: { gridColumn: '7/span 6' },
    [mq.xl]: {
      gridColumn: '7/span 5',
      height: '100%',
      gridRowStart: 1
    }
  }

  return (
    <Wrapper className='feedback-section' bgColor={BACKGROUNDS['blue']} css={{ position: 'relative' }}>
      <Grid
        css={{
          justifyItems: 'center',
          rowGap: 64,
          paddingBottom: 40,
          [mq.xs]: {
            rowGap: 40
          }
        }}
      >
        <div css={leftContainerStyle}>
          <H2 css={{ textAlign: 'center', [mq.lg]: { textAlign: 'start' } }}>{title}</H2>
        </div>
        {!!data.length && (
          <div css={rightContainerStyle}>
            <CarouselWrapper
              wrapperStyle={{
                maxWidth: 'none',
                width: '100%',
                [mq.lg]: {
                  maxWidth: 'none',
                  width: '100%'
                },
                [mq.xl]: {
                  maxWidth: 'none',
                  width: '100%'
                }
              }}
              containerStyle={{ paddingBlock: 0 }}
              content={data}
              slideMapper={({ slide }) => <FeedbackCard {...slide} />}
              buttonsStyle={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}
            />
          </div>
        )}
      </Grid>
    </Wrapper>
  )
}
