import { mq } from '@totmoney/grid'
import Grid from 'components/Grid'
import BACKGROUNDS from 'data/backgrounds'
import Wrapper from './Wrapper'
import { TextBlock } from './TextBlock'
import { BenefitCard, BenefitCardProps } from './BenefitCard'
import { useMediaQuery } from 'helpers/hooks/useMediaQuery'

export interface BenefitSectionProps {
  subtitle?: string
  title: string
  benefits: BenefitCardProps[]
}

export const BenefitSection = ({ subtitle, title, benefits }: BenefitSectionProps) => {
  const isDesktop = useMediaQuery('lg')

  const gridItemCardStyle = {
    height: '100%',
    display: 'grid',
    gap: 24,
    gridColumn: '1/-1',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [mq.lg]: {
      gridColumn: '6/-1'
    },
    [mq.xl]: {
      gridColumn: '7/-1'
    }
  }

  return (
    <Wrapper className='benefit-section' bgColor={BACKGROUNDS['white']}>
      <Grid css={{ alignItems: 'center', gap: 24 }}>
        <div
          css={{
            gridColumn: '1/-1',
            [mq.xs]: {
              marginBottom: 16
            },
            [mq.lg]: { marginBottom: 0, gridColumn: '1 / span 5', gridRow: 'span 2', alignSelf: 'start' },
            [mq.xl]: { gridColumn: '1 / span 6' }
          }}
        >
          <TextBlock title={title} copy={subtitle} {...(isDesktop && { textAlign: 'left' })} />
        </div>
        {!!benefits?.length && (
          <div css={gridItemCardStyle}>
            {benefits.map((benefit) => (
              <BenefitCard key={benefit.title} {...benefit} />
            ))}
          </div>
        )}
      </Grid>
    </Wrapper>
  )
}
