import { TestimonialsSectionProps } from 'components/TestimonialsSection'
import IlSoleLogo from './mock/img/il-sole-24-logo.png'
import LaRepubblicaLogo from './mock/img/la-repubblica-logo.png'
import ForbesLogo from './mock/img/forbes-logo.png'
import MfMilanoLogo from './mock/img/mf-milano-logo.png'
import { FeedbackSectionProps } from 'components/FeedbackSection'
import voltoTipo from '../public/images/Volto_tipo.png'
import voltoTipa from '../public/images/Volto_tipa.png'
import platformLogo from '../public/images/Trustpilot_Logo.svg'
import lottieHero from 'public/lotties/hero-desktop.json'
import { LottieHeroProps } from 'components/LottieHero'
import { MainComponentsSectionProps } from 'components/MainComponentsSection'
import lottieMainSection from 'public/lotties/background-main-section.json'
import { BenefitSectionProps } from 'components/BenefitSection'
import { Paragraph } from '@totmoney/typography'
import { RegistrationInfoSectionProps } from 'components/RegistrationInfoSection'
import discountImage from './mock/img/discount.png'
import discount2Image from './mock/img/discount2.png'
import discount3Image from './mock/img/discount3.png'
import { DiscountSectionProps } from 'components/DiscountSection'
import { mq } from '@totmoney/grid'
import styled from '@emotion/styled'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'
import AdministrationImage from './mock/img/administration.webp'
import CompanyAccountImage from './mock/img/company-account.webp'
import VatImage from './mock/img/vat.webp'
import SecurityImage from './mock/img/security.webp'
import RegistrationImage from './mock/img/registration.webp'
import { AdvantagesSectionProps } from 'components/AdvantagesSection'
import heroTablet from './mock/img/hero-illustration-tablet.svg'
import heroMobile from './mock/img/hero-illustration-mobile.svg'
import heroDesktop from './mock/img/hero-desktop.svg'
import { htmlStringToDocument } from 'contentful-rich-text-html-parser'
import { ComponentProps } from 'react'
import Footer from 'components/Footer'

interface homePageDataProps {
  testimonialsSection: TestimonialsSectionProps
  feedbackSection: FeedbackSectionProps
  homeHero: LottieHeroProps
  mainComponentsSection: MainComponentsSectionProps
  benefitSection: BenefitSectionProps
  registrationInfoSection: RegistrationInfoSectionProps
  discountSection: DiscountSectionProps
  advantagesSection: AdvantagesSectionProps
  disclaimerMock: ComponentProps<typeof Footer>['disclaimer']
}

const StyledLinkTrackEvent = styled(LinkTrackEvent)()

export const homePageData: homePageDataProps = {
  testimonialsSection: {
    title: 'Parlano di noi',
    data: [
      { src: IlSoleLogo, alt: 'il-sole-24-ore-logo' },
      { src: MfMilanoLogo, alt: 'mf-milano-logo' },
      { src: ForbesLogo, alt: 'forbes-logo' },
      { src: LaRepubblicaLogo, alt: 'la-repubblica-logo' }
    ]
  },
  feedbackSection: {
    title: 'Cosa dicono i clienti che ci hanno scelto',
    data: [
      {
        userReview: {
          name: 'Andrea',
          image: voltoTipo.src,
          rating: 5,
          platformLogo: { src: platformLogo, alt: 'Trustpilot_Logo', width: 68, height: 18 }
        },
        reviewText: `“Utilizzo tot da ormai un anno e mezzo, sono soddisfattissimo, sito semplice, intuitivo e di facile utilizzo, personale in chat molto competente, cortese e presente in caso di bisogno.”`
      },
      {
        userReview: {
          name: 'Diego',
          image: voltoTipo.src,
          rating: 5,
          platformLogo: { src: platformLogo, alt: 'Trustpilot_Logo', width: 68, height: 18 }
        },
        reviewText: `“La vera chicca è la sezione amministrazione che carica tutti i corrispettivi e le fatture sia in entrata che in uscita dal cassetto fiscale Agenzia Entrate e permette di pagarle con un click.”`
      },
      {
        userReview: {
          name: 'E.',
          image: voltoTipo.src,
          rating: 5,
          platformLogo: { src: platformLogo, alt: 'Trustpilot_Logo', width: 68, height: 18 }
        },
        reviewText: `"Interfaccia intuitiva e facile da usare. Bello che abbiano già i bonifici istantanei inclusi e senza commissioni, con un sistema che ne offre l'opzione quando riconosce in automatico se il destinatario ha un conto che li supporta."`
      },
      {
        userReview: {
          name: 'Vittoria',
          image: voltoTipa.src,
          rating: 5,
          platformLogo: { src: platformLogo, alt: 'Trustpilot_Logo', width: 68, height: 18 }
        },
        reviewText: `"E' un portale estremamente chiaro ed intuitivo da usare, dove tutte le operatività sono oltremodo facili da usare con una grafica essenziale senza tanti fronzoli inutili"`
      }
    ]
  },
  homeHero: {
    title: 'Oltre il conto aziendale',
    subtitle: 'Più flessibilità, più controllo, meno tempo perso.',
    copy: 'Scopri la piattaforma 100% online pensata per semplificare tutta la gestione amministrativa e finanziaria del tuo business.',
    bgColor: 'red',
    // label: 'Conto aziendale 2023',
    cta: [
      {
        type: 'button',
        href: 'https://app.tot.money/signup',
        ctaText: 'Apri conto'
      },
      {
        type: 'linkWithIcon',
        href: '/conto-aziendale',
        label: 'Scopri di più',
        icon: 'link'
      }
    ],
    lottie: lottieHero,
    lottieVisibility: {
      start: 0.4
    },
    imageTablet: { ...heroTablet, alt: 'hero-illustration-tot' },
    imageMobile: { ...heroMobile, alt: 'hero-illustration-tot' },
    imageDesktop: { ...heroDesktop, alt: 'hero-illustration-tot' },
    showLottie: false
  },
  mainComponentsSection: {
    lottie: lottieMainSection,
    data: [
      {
        subtitle: 'Conto aziendale 100% online',
        title: 'La flessibilità che hai sempre cercato',
        text: (
          <>
            <Paragraph>
              Con Tot hai un conto business con <span style={{ fontWeight: 'bold' }}>IBAN italiano</span> e{' '}
              <span style={{ fontWeight: 'bold' }}>bonifici SEPA istantanei, SDD, F24,</span> deleghe I24 con il
              collegamento a Entratel e Fisconline, pagamenti <span style={{ fontWeight: 'bold' }}>pagoPA</span> e{' '}
              <span style={{ fontWeight: 'bold' }}>RiBa</span>, tutti inclusi e{' '}
              <span style={{ fontWeight: 'bold' }}>senza commissioni.</span>{' '}
            </Paragraph>
            <Paragraph>
              Inoltre, hai inclusa e gratuita una <span style={{ fontWeight: 'bold' }}>carta di credito business</span>
              <sup>(1)</sup> e, con un click dalla tua area riservata, puoi richiedere tutte le{' '}
              <span style={{ fontWeight: 'bold' }}>carte aziendali aggiuntive</span> che vuoi, sia fisiche che virtuali.
            </Paragraph>
          </>
        ),
        links: [{ url: '/conto-aziendale', label: 'Scopri il conto' }],
        image: { url: CompanyAccountImage.src, description: 'image-tot' },
        name: 'La flessibilità che hai sempre cercato'
      },
      {
        subtitle: 'Contabilità aziendale semplificata',
        title: 'L’amministrazione semplice che non credevi possibile',
        text: (
          <>
            <Paragraph>
              Grazie allʼintegrazione con il Cassetto Fiscale online, filtri e categorizzi le tue fatture, fai la{' '}
              <span style={{ fontWeight: 'bold' }}>riconciliazione bancaria in automatico</span>, lʼexport per la{' '}
              <span style={{ fontWeight: 'bold' }}>prima nota</span> e{' '}
              <span style={{ fontWeight: 'bold' }}>paghi i tuoi fornitori con un click</span>, direttamente dalla
              fattura ricevuta. Tutto senza mai uscire dal tuo account.
            </Paragraph>
            <Paragraph>
              Inoltre, puoi dare lʼ<span style={{ fontWeight: 'bold' }}>accesso ai tuoi collaboratori</span> per farti
              supportare in tutta la gestione amministrativa e per predisporre i pagamenti, che poi controlli e approvi
              tu.
            </Paragraph>
          </>
        ),
        links: [{ url: '/amministrazione', label: 'Scopri di più' }],
        image: { url: AdministrationImage.src, description: 'image-tot' },
        imageFirst: true,
        name: 'L’amministrazione semplice che non credevi possibile'
      }
    ]
  },
  benefitSection: {
    title: 'Un partner affidabile per la tua gestione finanziaria',
    benefits: [
      {
        title: 'IBAN italiano',
        copy: 'Con tutti i pagamenti inclusi',
        icon: 'simpleCreditCard'
      },
      {
        title: '100% digital',
        icon: 'mobile',
        copy: 'lI tuo business sempre a portata di mano'
      },
      {
        title: `Piattaforma
        all-in-one`,
        icon: 'cube',
        copy: 'Banking e amministrazione in un’unica soluzione'
      },
      {
        title: 'Sicurezza on-top',
        icon: 'users',
        copy: 'Capitali tutelati fino a 100.000€'
      }
    ]
  },
  registrationInfoSection: {
    title: 'Al tuo fianco in ogni step',
    copy: 'Ti seguiamo dall’apertura del conto, fino all’ascolto dei tuoi consigli per  aggiungere sempre nuove funzionalità utili alla gestione del tuo business.',
    data: [
      {
        labelTab: 'Sicurezza',
        title: 'Tieni il tuo denaro al sicuro',
        text: (
          <>
            <Paragraph>
              Grazie alla partnership con Banca Sella, i servizi banking di Tot sono sempre{' '}
              <span style={{ fontWeight: 'bold' }}>
                conformi alle normative vigenti e i tuoi capitali tutelati fino a 100.000 euro
              </span>{' '}
              dal Fondo Interbancario per la tutela dei depositi a cui aderisce la banca. 
            </Paragraph>

            <StyledLinkTrackEvent
              type='linkWithIcon'
              icon='link'
              label='Apri un conto'
              href='https://app.tot.money/signup'
            />
          </>
        ),
        image: { src: SecurityImage, alt: 'image-tot' }
      },
      {
        labelTab: 'Onboarding',
        title: 'Registrati in pochi minuti',
        text: (
          <>
            <Paragraph>
              Dimenticati le lunghe attese e le code in banca per parlare con un consulente: con Tot,{' '}
              <span style={{ fontWeight: 'bold' }}>
                il processo di richiesta conto e di verifica dei documenti avviene completamente online,
              </span>{' '}
              in modo fluido, veloce e sicuro.
            </Paragraph>
            <StyledLinkTrackEvent
              type='linkWithIcon'
              icon='link'
              label='Apri un conto'
              href='https://app.tot.money/signup'
            />
          </>
        ),
        image: { src: RegistrationImage, alt: 'image-tot' }
      },
      {
        labelTab: 'Assistenza',
        title: 'Hai un team a tuo supporto',
        text: (
          <>
            <Paragraph>
              Gestire un business non è semplice: per questo in Tot troverai{' '}
              <span style={{ fontWeight: 'bold' }}>
                un team di customer support estremamente preparato e sempre pronto ad aiutarti,
              </span>{' '}
              non solo all’apertura del conto, ma anche per qualsiasi necessità quotidiana. 
            </Paragraph>
            <StyledLinkTrackEvent
              type='linkWithIcon'
              icon='link'
              label='Apri un conto'
              href='https://app.tot.money/signup'
            />
          </>
        ),
        image: { src: VatImage, alt: 'image-tot' }
      }
    ]
  },
  discountSection: {
    containerCardStyle: {
      [mq.xs]: { gridTemplateColumns: 'repeat(3, 1fr)' },
      [mq.lg]: { gridColumn: '1/-1' },
      [mq.xl]: { gridColumn: '1/-1' }
    },
    title: (
      <>
        Zero costi nascosti. <br />
        Scegli l’offerta migliore per la tua impresa
      </>
    ),
    copy: 'Confronta i piani, scegli l’offerta che meglio risponde alle esigenze del tuo business e richiedi il conto in pochi minuti.',
    cta: { label: 'Scopri di più', url: '/prezzi' },
    data: [
      {
        discountType: 'Essentials',
        price: '7',
        image: { ...discountImage, alt: 'image-tot' },
        cta: {
          ctaText: 'Apri conto',
          type: 'button',
          href: 'https://app.tot.money/signup'
        },
        copy: 'Primi 30 giorni gratuiti.'
      },
      {
        discountType: 'Plus',
        price: '15',
        image: { ...discount2Image, alt: 'image-tot' },
        cta: {
          ctaText: 'Apri conto',
          type: 'button',
          href: 'https://app.tot.money/signup'
        },
        copy: 'Prima carta Visa inclusa, ulteriori carte a pagamento.'
      },
      {
        discountType: 'Premium',
        price: '49',
        discountPrice: '32',
        image: { ...discount3Image, alt: 'image-tot' },
        cta: {
          ctaText: 'Apri conto',
          type: 'button',
          href: 'https://app.tot.money/signup'
        },
        copy: 'Prima carta Visa inclusa, ulteriori carte a pagamento.'
      }
    ]
  },
  advantagesSection: {
    title: 'Tutti i vantaggi di Tot',
    subtitle:
      'Scopri come migliorare la gestione delle spese della tua azienda e quali servizi ti mettiamo a disposizione',
    advantages: [
      {
        title: 'Conto aziendale',
        linkProps: { href: '/conto-aziendale' },
        icon: 'coins'
      },
      {
        title: 'Carta di credito',
        linkProps: { href: '/carta-di-credito-aziendale' },
        icon: 'creditCard'
      },
      {
        title: 'Carte di debito',
        linkProps: { href: '/carte-aziendali-di-debito-fisiche-e-virtuali' },
        icon: 'creditCard'
      },
      {
        title: 'Bonifici istantanei',
        linkProps: { href: '/bonifici' },
        icon: 'rocket'
      },
      {
        title: 'F24',
        linkProps: { href: '/f24' },
        icon: 'fileLines'
      },
      {
        title: 'Nuove aziende',
        linkProps: { href: '/nuove-aziende' },
        icon: 'briefCase'
      }
    ]
  },
  disclaimerMock: {
    json: htmlStringToDocument(
      `<p><b>NOTE</b></p><p><sup>1</sup>. La concessione del plafond di credito è un processo soggetto a valutazione creditizia da parte del nostro partner bancario. Per maggiori informazioni visita la pagina Carta,&nbsp;<a href="https://support.tot.money/it/?q=carta">consulta le nostre FAQ</a> oppure contatta il servizio clienti Tot.</p><p>-</p><p><b>Messaggio con finalità promozionale.</b>&nbsp;Consulta il <a href="https://www.sella.it/SSRDocumentDisplayer?dtdPG=C04_AZ_BSE&amp;dtdPE=1">Foglio informativo di Conto</a> ed il <a href="https://www.sella.it/SSRDocumentDisplayer?dtdPG=V09_BSE&amp;dtdPE=1">Foglio informativo di Carta di Credito</a> presenti nella sezione Trasparenza Bancaria nella <a href="https://tot.money/legale">pagina Legale</a> del presente sito web per conoscere tutti i dettagli dell'offerta Tot.</p>`
    )
  }
}
