import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { H4, Paragraph } from './Typography'
import styled from '@emotion/styled'
import { lightColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'
import { listIcon } from 'data/list-icons'

export interface BenefitCardProps {
  title: string
  copy: string
  icon: keyof typeof listIcon
}

const WrapperCard = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  flexDirection: 'column',
  height: '100%',
  'p, h4': { textAlign: 'center' },
  [mq.lg]: {
    padding: '20px 18px',
    alignItems: 'start',
    'p, h4': { textAlign: 'left' }
  }
})

export const BenefitCard = ({ title, copy, icon }: BenefitCardProps) => {
  return (
    <WrapperCard className='benefit-card'>
      {!!icon && (
        <FontAwesomeIcon
          css={{
            width: '24px',
            height: '24px',
            color: `var(--accent, ${lightColors.accent.base})`
          }}
          icon={listIcon[icon]}
        />
      )}
      {!!title && <H4>{title}</H4>}
      {!!copy && <Paragraph>{copy}</Paragraph>}
    </WrapperCard>
  )
}
