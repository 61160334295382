import { H4, Paragraph } from './Typography'
import styled from '@emotion/styled'
import Image, { ImageProps } from 'next/image'
import Link from 'next/link'
import { UserReviewWidget, UserReviewWidgetProps } from './UserReviewWidget'
import { mq } from '@totmoney/grid'

export interface FeedbackCardProps {
  reviewText: string
  userReview: UserReviewWidgetProps
}

const WrapperCard = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: 40,
  flexDirection: 'column',

  [mq.lg]: {
    alignItems: 'flex-start'
  }
})

export const FeedbackCard = ({ reviewText, userReview }: FeedbackCardProps) => {
  return (
    <WrapperCard className='feedback-card'>
      <H4 as='p' css={{ textAlign: 'center', [mq.lg]: { textAlign: 'start' } }}>
        {reviewText}
      </H4>
      <UserReviewWidget {...userReview} />
    </WrapperCard>
  )
}
