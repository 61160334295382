import { CSSObject } from '@emotion/styled'
import MainComponent, { MainComponentProps } from './MainComponent'
import { useLottie, useLottieInteractivity } from 'lottie-react'
import { mq } from '@totmoney/grid'
import Grid from './Grid'
import Wrapper from './Wrapper'

export interface MainComponentsSectionProps {
  lottie: any
  data: MainComponentProps[]
}

export const MainComponentsSection = ({ lottie, data }: MainComponentsSectionProps) => {
  const lottieStyle = {
    width: '100%',
    height: 'auto'
  }

  const lottieOptions = {
    animationData: lottie,
    autoPlay: false,
    loop: false,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
  }

  const lottieObj = useLottie(lottieOptions, lottieStyle)
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: 'scroll',
    actions: [
      {
        visibility: [0.1, 0.7],
        type: 'seek',
        frames: [0, 60]
      }
    ]
  })

  const imageContainerStyle: CSSObject = {
    display: 'grid',
    alignItems: 'center',
    gridColumn: '1/-1',
    [mq.xs]: {
      gridColumn: '2/-2'
    },
    [mq.xl]: {
      gridColumn: '3/-3'
    }
  }

  return (
    <Wrapper
      className='main-components-section'
      css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div
        css={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 216,
          [mq.xs]: { gap: 128 },
          [mq.lg]: { gap: 200 },
          [mq.xl]: { gap: 112 }
        }}
      >
        {!!data?.length &&
          data.map((el, i) => <MainComponent key={i} hideImageOnMobile={false} imageMinHeight={'271px'} {...el} />)}
      </div>
      <Grid css={{ position: 'absolute' }}>
        <div css={imageContainerStyle}>{Animation}</div>
      </Grid>
    </Wrapper>
  )
}
