import Grid from 'components/Grid'
import BACKGROUNDS from 'data/backgrounds'
import Wrapper from './Wrapper'
import { TextBlock } from './TextBlock'
import { SliderCard, SliderCardProps } from './SliderCard'
import { useState } from 'react'
import { Tabs } from '@totmoney/tabs'

export interface RegistrationInfoSectionProps {
  title: string
  copy: string
  data: (SliderCardProps & { labelTab: string })[]
}

export const RegistrationInfoSection = ({ title, copy, data }: RegistrationInfoSectionProps) => {
  const [indexTab, setIndexTab] = useState(0)

  return (
    <Wrapper className='registration-info-section' bgColor={BACKGROUNDS['grey']}>
      <Grid>
        <TextBlock title={title} copy={copy} />
      </Grid>
      {!!data?.length && (
        <>
          <Tabs
            variant='secondary'
            activeIndex={indexTab}
            css={{ display: 'flex', justifyContent: 'center', marginBlock: 40 }}
          >
            {data.map((tab, index) => (
              <button
                id={`tab-${index}`}
                aria-controls={`tab-${index}`}
                type='button'
                key={tab.labelTab}
                onClick={() => setIndexTab(index)}
              >
                {tab.labelTab}
              </button>
            ))}
          </Tabs>
          <Grid>
            <div css={{ gridColumn: '1/-1' }}>
              <SliderCard {...data[indexTab]} />
            </div>
          </Grid>
        </>
      )}
    </Wrapper>
  )
}
