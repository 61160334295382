import styled from '@emotion/styled'
import { faStar, faStarHalfStroke } from '@fortawesome/free-regular-svg-icons'
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '@totmoney/personitem'
import Image, { ImageProps } from 'next/image'
import { Label, Paragraph } from './Typography'
import { useMediaQuery } from 'helpers/hooks/useMediaQuery'
import { mq } from '@totmoney/grid'

export interface UserReviewWidgetProps {
  name: string
  image?: string
  role?: string
  rating: number
  platformLogo?: ImageProps
}

const WrapperCard = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  flexDirection: 'column',

  [mq.lg]: {
    flexDirection: 'row'
  }
})

export const UserReviewWidget = ({ name, image, role, rating, platformLogo }: UserReviewWidgetProps) => {
  const isLargeScreen = useMediaQuery('lg')

  const getRating = (rating: number) => {
    return [...new Array(5)].map((_, i) => {
      if (rating <= i + 0.2) {
        return faStar
      } else if (rating <= i + 0.7) {
        return faStarHalfStroke
      } else {
        return faSolidStar
      }
    })
  }

  return (
    <WrapperCard className='user-rewiev-card'>
      <Avatar name={name} remSize={4.875} imageSrc={image} style={{ background: '#DFF2FF' }} />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
          [mq.lg]: {
            alignItems: 'start'
          }
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            [mq.lg]: {
              alignItems: 'start'
            }
          }}
        >
          <Label size='m'>{name}</Label>
          {!!role && <Label size='s'>{role}</Label>}
        </div>

        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
            flexDirection: 'column',
            [mq.lg]: { flexDirection: 'row' }
          }}
        >
          <div css={{ display: 'flex', gap: 4 }}>
            <Paragraph weight='bold' css={{ marginRight: 2 }}>
              {rating}
            </Paragraph>
            {getRating(rating)?.map((item, i) => (
              <FontAwesomeIcon key={i} icon={item} style={{ width: 24, height: 24 }} />
            ))}
          </div>

          {platformLogo?.src && (
            <>
              {isLargeScreen && <div css={{ borderLeft: '1px solid #BDBDBD', height: 26 }} />}
              <Image {...platformLogo} style={{ objectFit: 'cover' }} />
            </>
          )}
        </div>
      </div>
    </WrapperCard>
  )
}
